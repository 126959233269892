<script>
  import { navigate } from 'svelte-navigator';
  import Button from '@/lib/components/button/Button.svelte';
  import { t } from '@/locales/i18n';
</script>

<div
  class="z-20 flex w-[27rem] flex-col items-center justify-center gap-3 rounded-lg px-14 xl:w-[35rem]"
>
  <p class="text-center text-2xl text-dark-grey">
    {@html $t('signup.info-text-1')}
  </p>
  <p class="text-center text-2xl text-dark-grey">
    {@html $t('signup.info-text-2')}
  </p>
  <div class="z-20 mt-5">
    <Button
      on:click={() => navigate('/')}
      rounded="rounded-md"
      testId="home-button"><span>{@html $t('signup.home-button')}</span></Button
    >
  </div>
</div>
