<script lang="ts">
  export let steps: readonly string[] = [];
  export let currentStep = '';
  export let styles = '';
  let stepIndex = 0;

  export function nextStep() {
    if (stepIndex < steps.length - 1) {
      stepIndex += 1;
      currentStep = steps[stepIndex]!;
    }
  }

  export function prevStep() {
    if (stepIndex > 0) {
      stepIndex -= 1;
      currentStep = steps[stepIndex]!;
    }
  }
</script>

<div class={styles}>
  <div class="flex h-fit w-full shrink-0 flex-row items-center">
    {#each steps as step}
      {#if steps.indexOf(step) !== 0}
        <div
          class="flex h-2 w-full flex-row items-start justify-start bg-light-grey px-10"
        >
          <div
            class={`h-full w-full origin-left bg-primary transition-all duration-300 ease-in-out ${
              steps.indexOf(step) <= steps.indexOf(currentStep)
                ? 'scale-x-100'
                : 'scale-x-0'
            }`}
          />
        </div>
      {/if}
      <div
        class="relative flex h-fit w-20 flex-col items-center justify-center"
      >
        <span
          class={`absolute -top-6 w-28 text-center text-lg transition-colors duration-300 ${
            steps.indexOf(step) <= steps.indexOf(currentStep)
              ? 'text-primary'
              : 'text-dark-grey'
          }`}>{step}</span
        >
        <div
          class={`flex h-10 w-10 items-center justify-center rounded-full border-[.35rem] bg-white transition-colors duration-300 ${
            steps.indexOf(step) <= steps.indexOf(currentStep)
              ? 'border-primary'
              : 'border-light-grey'
          }`}
        >
          <span class="font-bold text-dark-grey">{steps.indexOf(step) + 1}</span
          >
        </div>
      </div>
    {/each}
  </div>
</div>
