<script lang="ts">
  import { navigate } from 'svelte-navigator';
  import ChevronIcon from '@/assets/svg/ChevronIcon.svelte';
  import { languageEnumTranslation } from '@/interface/IExperience';
  import type { ITeam } from '@/interface/ITeam';
  import type { IUser } from '@/interface/IUser';
  import Button from '@/lib/components/button/Button.svelte';
  import ChangeUserIcon from '@/lib/components/icons/ChangeUserIcon.svelte';
  import PlusIcon from '@/lib/components/icons/PlusIcon.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { openModal } from '@/lib/components/modal/modal.store';
  import AddTeamMemberModal from '@/lib/components/modal/modals/AddTeamMemberModal.svelte';
  import ChangeHostModal from '@/lib/components/modal/modals/ChangeHostModal.svelte';
  import ConfirmationModal from '@/lib/components/modal/modals/ConfirmationModal.svelte';
  import CreateTeamModal from '@/lib/components/modal/modals/CreateTeamModal.svelte';
  import UserTable from '@/lib/components/tables/UserTable.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import {
    getTeamById,
    removeTeamMember,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';

  import TeamSessionsSection from './TeamSessionsSection.svelte';

  export let id: string;

  let team: ITeam | null = null;
  let loading = true;

  const fetchTeam = async () => {
    loading = true;
    team = await getTeamById(Number(id));
    loading = false;
  };

  const handleEditTeam = async (team: ITeam) => {
    openModal(CreateTeamModal, {
      allowCloseClick: true,
      props: { team, onSubmit: fetchTeam },
    });
  };

  const handleChangeHost = async (team: ITeam) => {
    openModal(ChangeHostModal, {
      allowCloseClick: true,
      props: { team, onSubmit: fetchTeam },
    });
  };

  const handleAddMember = async (team: ITeam) => {
    openModal(AddTeamMemberModal, {
      allowCloseClick: true,
      props: { team, onSubmit: fetchTeam },
    });
  };

  const handleRemoveMember = async (user: IUser) => {
    const confirmRemoveMember = async (user: IUser) => {
      try {
        await removeTeamMember(+id, user.id);
        fetchTeam();
        ToastNotification.success(
          $t('dashboard.teams.view.remove-member-modal-success'),
        );
      } catch (error) {
        ToastNotification.error(
          $t('dashboard.teams.view.remove-member-modal-error'),
        );
      }
    };

    openModal(ConfirmationModal, {
      allowCloseClick: false,
      props: {
        title: $t('dashboard.teams.view.remove-member-modal-title'),
        text:
          $t('dashboard.teams.view.remove-member-modal-text') +
          ' ' +
          user.name +
          ' ' +
          user.lastName +
          '?',
        onSubmit: () => confirmRemoveMember(user),
      },
    });
  };

  const actions = [
    {
      name: $t('dashboard.teams.view.table-action-remove'),
      onAction: handleRemoveMember,
    },
  ];

  $: fetchTeam();
</script>

<div class="flex h-full w-full flex-col">
  {#if loading}
    <div class="flex h-full w-full grow flex-col items-center justify-center">
      <Loading loadingSize="h-32 w-32" />
    </div>
  {:else if team}
    <div
      class="flex h-16 w-full flex-row items-center justify-start border-b border-light-grey px-6"
    >
      <div
        class="flex flex-row items-center gap-2 py-4 hover:cursor-pointer"
        on:keypress
        on:click={() => navigate(-1)}
      >
        <ChevronIcon style="rotate-180 fill-primary" />
        <h1 class="mt-1 text-xl font-bold text-primary">{team.name}</h1>
      </div>
    </div>
    <div class="flex flex-row gap-6 p-6">
      <div class="flex flex-col gap-2">
        <h2 class="text-2xl font-bold text-primary">
          {$t('dashboard.teams.view.team-title')}
        </h2>
        <div
          class="flex w-[36rem] flex-col gap-4 rounded-lg border border-light-grey bg-white p-8"
        >
          <div class="flex flex-row justify-between">
            <div class="flex flex-col gap-1">
              <div class="flex flex-row items-center gap-12">
                <span>Host</span>
                <ChangeUserIcon
                  on:click={() => team && handleChangeHost(team)}
                  style="h-6 w-6 cursor-pointer bottom-[.2rem]"
                  bgColor="bg-white"
                  fillColor="fill-primary"
                  testId="change-host-button"
                />
              </div>
              <span class="font-semibold text-dark-grey"
                >{team.host.name} {team.host.lastName}</span
              >
            </div>

            <div class="flex flex-row gap-2">
              <div class="flex flex-col gap-1">
                <span>{$t('dashboard.teams.view.language-label')}:</span>
                <span>{$t('dashboard.teams.view.monthly-sessions-label')}:</span
                >
              </div>

              <div class="flex flex-col gap-1">
                <span class="font-semibold text-dark-grey"
                  >{languageEnumTranslation(team.language)}</span
                >
                <span class="font-semibold text-dark-grey"
                  >{team.monthlySession
                    ? $t('dashboard.teams.view.monthly-sessions.enabled')
                    : $t(
                        'dashboard.teams.view.monthly-sessions.disabled',
                      )}</span
                >
              </div>

              <Button
                on:click={() => team && handleEditTeam(team)}
                testId="edit-team-button"
                >{$t('dashboard.teams.view.edit-team-button')}</Button
              >
              <Button
                testId="add-member-button"
                on:click={() => team && handleAddMember(team)}
                style="outline"
                ><PlusIcon /><span
                  >{$t('dashboard.teams.view.add-member-button')}</span
                ></Button
              >
            </div>
          </div>
          {#if team.users.length > 0}
            <UserTable
              hideColumns={['role']}
              actions={actions}
              users={team.users}
            />
          {:else if team.users.length === 0}
            <div
              class="flex h-80 w-full grow items-center justify-center text-dark-grey"
            >
              {$t('dashboard.teams.view.member.table-no-members')}
            </div>
          {:else}
            <div class="flex h-80 w-full grow items-center justify-center">
              <Loading loadingSize="h-32 w-32" />
            </div>
          {/if}
        </div>
      </div>
      <div class="flex grow flex-col gap-2">
        <h2 class="text-2xl font-bold text-primary">
          {$t('dashboard.teams.view.team-sessions-title')}
        </h2>
        <TeamSessionsSection teamId={+id} />
      </div>
    </div>
  {/if}
</div>
