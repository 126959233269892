import moment from 'moment';
import { get } from 'svelte/store';
import * as yup from 'yup';
import type { SubmissionFormType } from '@/lib/components/signup-form/@types/SubmissionFormTypes';
import { t } from '@/locales/i18n';
import { submissionFormStore } from '@/store/submissionFormStore';

const translate = get(t);
let submissionStore: SubmissionFormType;

submissionFormStore.subscribe((value) => {
  submissionStore = value;
});

export const signUpFormSchema = yup.object({
  orgName: yup
    .string()
    .required(translate('signup.form-orgname-required'))
    .min(2, translate('signup.form-orgname-min'))
    .max(25, translate('signup.form-orgname-max')),
  name: yup
    .string()
    .required(translate('signup.form-name-required'))
    .min(2, translate('signup.form-name-min'))
    .max(25, translate('signup.form-name-max')),
  lastName: yup
    .string()
    .required(translate('signup.form-lastname-required'))
    .min(2, translate('signup.form-lastname-min'))
    .max(25, translate('signup.form-lastname-max')),
  email: yup
    .string()
    .email(translate('signup.form-email-invalid'))
    .required(translate('signup.form-email-required')),
  acceptTerms: yup
    .boolean()
    .oneOf([true], translate('signup.form-terms-required')),
});

export const trialSessionFormSchema = yup.object({
  applyTrial: yup.boolean(),
  trialSessionScheduleDate: yup
    .string()
    .when('applyTrial', ([applyTrial], schema) => {
      if (applyTrial) {
        return schema
          .required(translate('signup.form-trial-date-required'))
          .test(
            'is-not-future',
            translate('signup.form-trial-date-not-past'),
            function (value) {
              const now = moment();
              return !moment(value).isSameOrBefore(now);
            },
          );
      }
      return schema.notRequired();
    }),
  trialSessionParticipantsMails: yup
    .string()
    .when('applyTrial', ([applyTrial], schema) => {
      if (applyTrial) {
        return schema
          .required(translate('signup.form-trial-mail-required'))
          .matches(
            /^(?!.*((?:(?<![\w.\-+%])[\w._%+-]+@[\w.-]+.[a-zA-Z]{2,}\b)).*\b\1\b)(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:,(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))*$/,
            translate('signup.form-trial-session-participants-mails-invalid'),
          )
          .test(
            'email-list',
            translate('signup.form-trial-session-participants-mails-repeated'),
            function (value) {
              const emails = value.split(',');
              const signupEmail = submissionStore?.email;
              const emailRegex =
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              return emails.every((email) => {
                const trimmedEmail = email.trim();
                return (
                  emailRegex.test(trimmedEmail) && trimmedEmail !== signupEmail
                );
              });
            },
          );
      }
      return schema.notRequired();
    }),
});
