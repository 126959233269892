<script lang="ts">
  import moment from 'moment';
  import type { ComponentProps } from 'svelte/internal';
  import { SessionStatus, type ISession } from '@/interface/ISession';
  import { UserRole } from '@/interface/IUser';
  import { openModal } from '@/lib/components/modal/modal.store';
  import { ToastNotification } from '@/lib/services/toast';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  import Badge from '../../badge/Badge.svelte';
  import Button from '../../button/Button.svelte';
  import SessionUsersModal from '../../modal/modals/SessionUsersModal.svelte';

  export let sessions: ISession[] = [];
  export let actions: {
    name: string;
    onAction: (session: ISession) => void;
    disable?: (session: ISession) => boolean;
    hideOnDisabled?: boolean;
  }[] = [];

  const handleCopyFeedbackLink = async (session: ISession) => {
    await navigator.clipboard.writeText(
      import.meta.env.VITE_BASE_URL + `/session/${session.id}/feedback`,
    );
    ToastNotification.success($t('dashboard.sessions.feedback-copy-success'));
  };

  const handleOpenFeedbackPage = (session: ISession) => {
    window.open(
      import.meta.env.VITE_BASE_URL + `/session/${session.id}/feedback`,
      '_blank',
    );
  };

  const handleShowSessionUsers = async (session: ISession) => {
    openModal(SessionUsersModal, { props: { session } });
  };

  const handleOpenBoard = (session: ISession) => {
    window.open(session.boardUrl, '_blank');
  };

  const isFinishedAndHasUnratedUsers = (session: ISession) => {
    return (
      session.status === SessionStatus.FINISHED &&
      $userStore?.role !== UserRole.USER &&
      session.users.some(
        (sessionUser) =>
          sessionUser.feedbackRating === 0 ||
          sessionUser.feedbackRating === null,
      )
    );
  };

  const isUserAndHasUnratedFeedback = (session: ISession) => {
    return (
      session.status === SessionStatus.FINISHED &&
      $userStore?.role === UserRole.USER &&
      session.users.some(
        (sessionUser) =>
          sessionUser.user.id === $userStore?.id && !sessionUser.feedbackRating,
      )
    );
  };

  const statusTranslate = (status: SessionStatus): ComponentProps<Badge> => {
    switch (status) {
      case SessionStatus.FINISHED:
        return { text: $t('session-status-finished'), color: 'green' };
      case SessionStatus.CANCELED:
        return { text: $t('session-status-canceled'), color: 'red' };
      case SessionStatus.PENDING:
        return { text: $t('session-status-pending'), color: 'yellow' };
      default:
        return { text: 'no status', color: 'default' };
    }
  };
</script>

<table class="w-full text-left text-sm text-dark-grey">
  <thead>
    <tr class="h-10">
      <th scope="col"><span>{$t('dashboard.sessions.table-name')}</span></th>
      <th scope="col"><span>{$t('dashboard.sessions.table-host')}</span></th>
      <th scope="col"
        ><span>{$t('dashboard.sessions.table-experience')}</span></th
      >
      <th scope="col"
        ><span>{$t('dashboard.sessions.table-participants')}</span></th
      >
      <th scope="col"><span>{$t('dashboard.sessions.table-date')}</span></th>
      <th scope="col"><span>{$t('dashboard.sessions.table-status')}</span></th>

      <th scope="col"><span>{$t('dashboard.sessions.table-actions')}</span></th>
    </tr>
  </thead>
  <tbody>
    {#each sessions as session}
      <tr class="h-16">
        <td><span>{session.name}</span></td>
        <td><span>{session.host.name} {session.host.lastName}</span></td>
        <td><span>{session.experience.name}</span></td>
        <td>
          <Button
            testId={`participants-button-${session.id}`}
            on:click={() => handleShowSessionUsers(session)}
          >
            <span>{session.users.length}</span>
          </Button>
        </td>
        <td><span>{moment(session.scheduledDate).format('DD MMM YY')}</span></td
        >
        <td
          ><Badge
            color={statusTranslate(session.status).color}
            text={statusTranslate(session.status).text}
          /></td
        >
        <td>
          <div class="flex h-16 flex-row items-center justify-start gap-2">
            {#if isFinishedAndHasUnratedUsers(session)}
              <Button
                testId={`copy-feedback-link-button-${session.id}`}
                on:click={() => handleCopyFeedbackLink(session)}
              >
                <span
                  >{$t('dashboard.sessions.table-action-copy-feedback')}</span
                >
              </Button>
            {/if}
            {#if isUserAndHasUnratedFeedback(session)}
              <Button
                testId={`feedback-button-${session.id}`}
                on:click={() => handleOpenFeedbackPage(session)}
              >
                <span
                  >{$t('dashboard.sessions.table-action-send-feedback')}</span
                >
              </Button>
            {/if}
            {#each actions as { name, onAction, disable, hideOnDisabled }}
              {#if !(hideOnDisabled && disable?.(session))}
                <Button
                  testId={`${name}-button-${session.id}`}
                  disabled={disable?.(session)}
                  on:click={() => onAction(session)}
                >
                  <span>{name}</span>
                </Button>
              {/if}
            {/each}

            {#if session.status !== SessionStatus.CANCELED}
              <Button
                testId={`board-button-${session.id}`}
                on:click={() => handleOpenBoard(session)}
              >
                <span>{$t('dashboard.sessions.table-action-board')}</span>
              </Button>
            {/if}
          </div>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
