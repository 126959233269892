<script lang="ts">
  import type { ComponentProps } from 'svelte/internal';
  import { SessionStatus, type ISession } from '@/interface/ISession';
  import { UserRole } from '@/interface/IUser';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import {
    handleCancelSession,
    handleEditSessionModal,
    handleFinishSession,
  } from '@/lib/components/tables/session-table/session-table-common-actions';
  import SessionTable from '@/lib/components/tables/session-table/SessionTable.svelte';
  import {
    getSessions,
    type ISessionQuery,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  export let teamId: number;

  let loading = false;
  let sessions: ISession[] | null = null;
  let count = 0;

  let sessionQuery: ISessionQuery = {
    limit: 10,
    offset: 0,
    teamId: teamId,
  };

  const fetchSessions = async () => {
    loading = true;
    const result = await getSessions(sessionQuery);
    sessions = result.sessions;
    count = result.count;
    loading = false;
  };

  const canModifyCheck = (session: ISession) => {
    const isHost = session.host.id === $userStore?.id;
    const isAdmin = $userStore?.role === UserRole.ADMIN || $userStore?.role === UserRole.SUPER_ADMIN;
    const sessionCanBeModified = session.status === SessionStatus.PENDING;
    return (isHost || isAdmin) && sessionCanBeModified;
  };

  const actions: ComponentProps<SessionTable>['actions'] = [
    {
      name: $t('dashboard.sessions.table-action-finish'),
      onAction: (session: ISession) =>
        handleFinishSession(session, fetchSessions),
      hideOnDisabled: true,
      disable: (session: ISession) => !canModifyCheck(session),
    },
    {
      name: $t('dashboard.teams.view.session-table-action-edit'),
      onAction: (session: ISession) =>
        handleEditSessionModal(session, fetchSessions),
      hideOnDisabled: true,
      disable: (session: ISession) => !canModifyCheck(session),
    },
    {
      name: $t('dashboard.teams.view.session-table-action-cancel'),
      onAction: (session: ISession) =>
        handleCancelSession(session, fetchSessions),
      hideOnDisabled: true,
      disable: (session: ISession) => !canModifyCheck(session),
    },
  ];

  $: sessionQuery, fetchSessions();
</script>

<div class="flex grow flex-col gap-4 rounded-md border border-light-grey p-6">
  {#if loading}
    <div class="flex h-full w-full grow flex-col items-center justify-center">
      <Loading loadingSize="h-32 w-32" />
    </div>
  {:else if sessions}
    <SessionTable sessions={sessions} actions={actions} />
    <Paginator
      total={count}
      query={sessionQuery}
      onChange={(newQuery) => (sessionQuery = { ...newQuery })}
    />
  {/if}
</div>
