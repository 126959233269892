import type { ITenant } from '@/interface/IUser';
import { get, patch } from '@/lib/services/axios/api-axios';

type UpdateTenantProfilePayload = {
  name: string;
};
export const getAllTenants = async (): Promise<ITenant[]> => {
  return get<ITenant[]>('/tenant');
};

export const updateTenantProfile = async (
  tenant: UpdateTenantProfilePayload,
): Promise<void> => {
  return patch<void>('/tenant/profile', {
    name: tenant.name,
  });
};
