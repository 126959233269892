<script lang="ts">
  import type { IExperience } from '@/interface/IExperience';
  import type { ISession } from '@/interface/ISession';
  import type { IUser } from '@/interface/IUser';
  import SearchInput from '@/lib/components/search-input/SearchInput.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import {
    getExperiences,
    getTenantUsers,
    updateSession,
    updateSessionExperience,
    updateSessionUsers,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';

  import Button from '../../button/Button.svelte';
  import XIcon from '../../icons/XIcon.svelte';
  import Input from '../../input/Input.svelte';
  import InviteUserList from '../../lists/InviteUserList.svelte';
  import Loading from '../../loading/Loading.svelte';
  import { closeModal } from '../modal.store';

  export let onSubmit: (() => void) | undefined = undefined;
  export let session: ISession;
  let selectedUserIds = new Set<IUser['id']>(
    session.users.map((u) => u.user.id),
  );

  let sessionName = session.name;
  let scheduledDate = session.scheduledDate.slice(0, 10);
  let isUpdatingSession = false;
  let tenantUsers: IUser[] = [];
  let filteredUsers: IUser[] = [];
  let experienceOptions: IExperience[] = [];
  let selectedExperienceId: string | undefined = String(session.experience.id);

  const fetchExperiences = async () => {
    try {
      const { experiences } = await getExperiences({
        language: session.experience.language,
        limit: 100,
        offset: 0,
      });
      experienceOptions = experiences;
      return;
    } catch (error) {
      console.error(error);
      ToastNotification.error(
        $t('dashboard.teams.view.update-session-modal.experiences-error'),
      );
      return [];
    }
  };

  const getUsers = async () => {
    try {
      const { users } = await getTenantUsers();
      tenantUsers = users;

      filteredUsers = tenantUsers;

      return filteredUsers;
    } catch (error) {
      console.error(error);
      ToastNotification.error(
        $t('dashboard.teams.view.update-session-modal.users-error'),
      );
      return [];
    }
  };

  const handleSelect = (user: IUser) => {
    selectedUserIds.has(user.id)
      ? selectedUserIds.delete(user.id)
      : selectedUserIds.add(user.id);
    selectedUserIds = new Set(selectedUserIds);
  };

  const handleSearch = (event: CustomEvent<any>) => {
    const { searchBy, searchQuery } = event.detail;
    filteredUsers = tenantUsers.filter((user) =>
      searchBy === 'name'
        ? `${user.name} ${user.lastName}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        : user.email.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };

  const handleUpdateSession = async () => {
  isUpdatingSession = true;
  try {
    await updateSessionUsers(session.id, Array.from(selectedUserIds));
    await updateSession(session.id, {
      name: sessionName,
      scheduledDate,
    });

    if (String(session.experience.id) !== selectedExperienceId) {
      await updateSessionExperience(session.id, +selectedExperienceId!);
    }

    ToastNotification.success(
      $t('dashboard.teams.view.update-session-modal.success'),
    );
    onSubmit?.();
    closeModal();
  } catch (error) {
    console.error(error);
    ToastNotification.error(
      $t('dashboard.teams.view.update-session-modal.error'),
    );
  } finally {
    isUpdatingSession = false;
  }
};

</script>

<div
  class="flex w-fit flex-col items-center justify-between gap-6 rounded-lg bg-white p-4"
>
  <div class="flex w-full flex-col gap-4">
    <div class="flex w-full flex-row gap-1">
      <div class="flex w-full flex-col gap-1">
        <span class="font-baloo font-bold"
          >{$t('dashboard.teams.view.update-session-modal.title')}</span
        >
        <span class="text-sm text-grey">
          {$t('dashboard.teams.view.update-session-modal.subtitle')}
        </span>
      </div>
      <div class="h-full">
        <button on:click={closeModal}>
          <XIcon
            style="w-6 h-6 hover:bg-primary hover:text-white rounded-full"
          />
        </button>
      </div>
    </div>
    {#await getUsers()}
      <Loading loadingSize="h-20 w-20" />
    {:then users}
      {#if users}
        <div class="flex w-full flex-row gap-4">
          <SearchInput
            searchByOptions={['name', 'email']}
            on:search={handleSearch}
          />
        </div>

        <InviteUserList
          users={filteredUsers}
          selectedUserIds={selectedUserIds}
          onSelect={handleSelect}
        />
      {:else}
        <p>{$t('dashboard.teams.view.update-session-modal.no-users')}</p>
      {/if}
    {/await}
  </div>
  <div class="flex h-full w-full flex-row gap-4 self-start">
    <Input
      testId="session-name-input"
      bind:value={sessionName}
      label="Session Name"
      placeholder="Enter session name"
      error={!sessionName.trim().match(/^[a-zA-Z0-9 ]+$/)
        ? $t('dashboard.experiences.start-session-name-input-error')
        : ''}
    />

    <Input
      testId="session-date-input"
      bind:value={scheduledDate}
      label="Scheduled Date"
      type="date"
      minDate={new Date().toISOString().slice(0, 10)}
    />
    {#await fetchExperiences()}
      <Loading loadingSize="h-10 w-10" />
    {:then}
      <Input
        testId="session-experience-select"
        type="select"
        label="Experience"
        bind:value={selectedExperienceId}
        on:change={(e) => console.log(e)}
        options={experienceOptions.map((e) => ({
          value: String(e.id),
          label: e.name,
        }))}
      />
    {/await}
  </div>
  <div class="flex w-full flex-row items-center justify-end">
    {#if !isUpdatingSession}
      <Button
        testId="update-session-confirm-button"
        on:click={handleUpdateSession}
      >
        <span>{$t('dashboard.teams.view.update-session-modal.confirm')}</span>
      </Button>
    {:else}
      <Loading loadingSize="h-8 w-8" />
    {/if}
  </div>
</div>
