<script lang="ts">
  import { validator } from '@felte/validator-yup';
  import { createForm } from 'felte';
  import * as yup from 'yup';
  import Input from '@/lib/components/input/Input.svelte';
  import Page from '@/lib/components/page/Page.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import { updateTenantProfile } from '@/lib/services/together/tenant.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  const schema = yup.object({
    name: yup
      .string()
      .required($t('dashboard.settings.organization.form.name-required'))
      .max(40, $t('dashboard.settings.organization.form.name-max')),
  });

  const { form, isValid, errors, isSubmitting } = createForm<
    yup.InferType<typeof schema>
  >({
    initialValues: {
      name: $userStore?.tenant?.name || '',
    },
    extend: [validator({ schema })],
    onSubmit: async (values) => {
      try {
        await updateTenantProfile({ name: values.name });
        ToastNotification.success(
          $t('dashboard.settings.organization.form.submit-success'),
          {
            duration: 2000,
            onpop: () => {
              window.location.reload();
            },
          },
        );
      } catch (error: any) {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage) {
          ToastNotification.error(errorMessage);
        } else {
          ToastNotification.error(
            $t('dashboard.settings.organization.form.submit-error'),
          );
        }
      }
    },
  });
</script>

<Page>
  <div
    class="h-max-fit flex w-full flex-col gap-4 rounded-none border-x border-t border-light-grey bg-white p-8"
  >
    <h1 class="mt-1 text-3xl font-bold">
      {$t('dashboard.settings.organization.title')}
    </h1>
    <p>{$t('dashboard.settings.organization.description')}</p>
    <form use:form class="mt-4">
      <div class="mb-4 w-1/2">
        <label for="name" class="text-md ms-1 block font-medium text-gray-700"
          >{$t('dashboard.settings.organization.form-name-label')}</label
        >
        <Input
          type="text"
          placeholder={$t(
            'dashboard.settings.organization.form-name-placeholder',
          )}
          name="name"
          styleClass="mt-1"
          testId="organization-name-input"
        />
        {#if $errors.name}
          <p class="mt-2 text-sm text-red-600">{$errors.name}</p>
        {/if}
      </div>
      <div
        class="fixed bottom-0 left-0 right-0 z-10 border-t-2 bg-gray-100 p-4"
      >
        <div class="flex w-full flex-row items-center justify-end gap-3">
          <button
            type="submit"
            disabled={$isSubmitting || !$isValid}
            class="hover:bg-primary-focus rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 {$isSubmitting ||
            !$isValid
              ? 'cursor-not-allowed bg-primary/50'
              : ''}"
            data-cy="organization-settings-submit-btn"
          >
            {$t('dashboard.settings.organization.form-submit-btn')}
          </button>
        </div>
      </div>
    </form>
  </div>
</Page>
