<script lang="ts">
  import type { ComponentProps } from 'svelte';
  import { navigate } from 'svelte-navigator';
  import type { ITeam } from '@/interface/ITeam';
  import Button from '@/lib/components/button/Button.svelte';
  import PlusIcon from '@/lib/components/icons/PlusIcon.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { openModal } from '@/lib/components/modal/modal.store';
  import ConfirmationModal from '@/lib/components/modal/modals/ConfirmationModal.svelte';
  import CreateTeamModal from '@/lib/components/modal/modals/CreateTeamModal.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import SearchUserInput from '@/lib/components/search-input/SearchInput.svelte';
  import TeamTable from '@/lib/components/tables/TeamTable.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import {
    deleteTeam,
    getTeams,
    type ITeamsQuery,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';

  let query: ITeamsQuery = {
    name: '',
    limit: 7,
    offset: 0,
  };

  let teams: ITeam[] = [];
  let filteredTeams: ITeam[] = [];
  let count = 0;

  const fetchTeams = async () => {
    const { teams: newTeams, count: newCount } = await getTeams(query);
    teams = newTeams;
    filteredTeams = teams;
    count = newCount;
  };

  const handleCreateTeam = () => {
    openModal(CreateTeamModal, {
      allowCloseClick: false,
      onClose: fetchTeams,
    });
  };

  const handleSearch = (event: CustomEvent<any>) => {
    const { searchQuery } = event.detail;
    query.offset = 0;
    query.name = searchQuery;
  };

  const handleDeleteTeam = async (team: ITeam) => {
    const confirmDeleteUser = async (team: ITeam) => {
      try {
        await deleteTeam(team.id);
        if (
          teams.length === 1 &&
          query?.offset &&
          query?.offset > 0 &&
          query?.limit
        ) {
          query.offset = query?.offset - query?.limit;
        }
        fetchTeams();
        ToastNotification.success('Team deleted successfully');
      } catch (error) {
        console.error(error);
        ToastNotification.error('Error deleting team');
      }
    };
    openModal(ConfirmationModal, {
      allowCloseClick: false,
      props: {
        title: $t('dashboard.teams.delete-team-modal-title'),
        text: $t('dashboard.teams.delete-team-modal-text') + team.name + '?',
        onSubmit: () => confirmDeleteUser(team),
      },
    });
  };

  const actions: ComponentProps<TeamTable>['actions'] = [
    {
      name: $t('dashboard.teams.table-actions-manage'),
      onAction: (team: ITeam) => navigate(`/dashboard/teams/${team.id}`),
    },
    {
      name: $t('dashboard.teams.table-actions-delete'),
      onAction: handleDeleteTeam,
    },
  ];

  $: query, count, fetchTeams();
</script>

<div class="flex h-full w-full flex-col">
  <div
    class="flex h-16 w-full flex-row items-center justify-start border-b border-light-grey px-6"
  >
    <h1 class="mt-1 text-xl font-bold text-primary">
      {$t('dashboard.teams.title')}
    </h1>
  </div>
  <div class="p-6">
    <div
      class="flex w-full flex-col gap-4 rounded-lg border border-light-grey bg-white p-8"
    >
      <div class="flex flex-row justify-between">
        <div>
          <SearchUserInput
            searchByOptions={['name']}
            isAsync
            on:search={handleSearch}
          />
        </div>
        <div>
          <Button
            testId="add-team-button"
            on:click={handleCreateTeam}
            style="outline"
            ><PlusIcon /><span>{$t('dashboard.teams.add-team-button')}</span
            ></Button
          >
        </div>
      </div>
      {#if teams.length > 0}
        <TeamTable actions={actions} teams={filteredTeams} />

        <Paginator
          total={count}
          query={query}
          onChange={(newQuery) => {
            query = { ...newQuery };
          }}
        />
      {:else if teams.length === 0}
        <div
          class="flex h-80 w-full grow items-center justify-center text-dark-grey"
        >
          {'No teams found'}
        </div>
      {:else}
        <div class="flex h-80 w-full grow items-center justify-center">
          <Loading loadingSize="h-32 w-32" />
        </div>
      {/if}
    </div>
  </div>
</div>
