import { get } from 'svelte/store';
import { locale } from '@/locales/i18n';

import type { ICategory } from './ICategory';

export enum SubscriptionPlan {
  FREE = 0,
  PRO = 1,
}

export const planNames = ['Free', 'Pro'] as const;

export enum ExperienceLanguages {
  english = 'English',
  spanish = 'Spanish',
}

export const languageEnumTranslation = (language: ExperienceLanguages) => {
  switch (language) {
    case ExperienceLanguages.english:
      return get(locale) === 'en' ? 'English' : 'Inglés';
    case ExperienceLanguages.spanish:
      return get(locale) === 'en' ? 'Spanish' : 'Español';
  }
};
export interface IExperience {
  id: number;
  name: string;
  description: string;
  instructions: string;
  imageUrl: string;
  templateUrl: string;
  documentUrl?: string;
  durationInMinutes: number;
  category: string | ICategory;
  allowInPlan: SubscriptionPlan;
  language: ExperienceLanguages;
  totalRatings: number;
  totalRaters: number;
  minParticipants: number;
  maxParticipants: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
}
