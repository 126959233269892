<script lang="ts">
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-navigator';
  import type { ICategory } from '@/interface/ICategory';
  import {
    ExperienceLanguages,
    type IExperience,
  } from '@/interface/IExperience';
  import ExperienceCard from '@/lib/components/cards/ExperienceCard.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import {
    getAllCategories,
    getExperiences,
    type IExperienceQuery,
  } from '@/lib/services/together/together.api';
  import { locale, t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  let query: IExperienceQuery = {
    limit: 6,
    offset: 0,
    categoryId: undefined,
    language:
      $locale === 'en'
        ? ExperienceLanguages.english
        : ExperienceLanguages.spanish,
  };

  let categories: ICategory[] = [];

  let experiencesPromise: Promise<{
    experiences: IExperience[];
    count: number;
  }>;

  let selectedLanguage: string =
    $locale === 'en'
      ? ExperienceLanguages.english
      : ExperienceLanguages.spanish;

  const fetchExperiences = () => {
    experiencesPromise = getExperiences(query);
  };
  $: query, fetchExperiences();

  const handleExperienceDetails = (experience: IExperience) => {
    navigate(`/dashboard/experiences/${experience.id}`);
  };

  const checkIfAllowed = (experience: IExperience) =>
    $userStore?.tenant.subscriptionPlan !== undefined &&
    $userStore.tenant.subscriptionPlan < experience.allowInPlan;

  const handleLanguageChange = (language: string) => {
    query = { ...query, language: language as ExperienceLanguages };
  };

  const handleCategoryChange = (categoryId: number) => {
    query = {
      ...query,
      categoryId: categoryId === 0 ? undefined : categoryId,
    };
  };

  onMount(async () => {
    const result = await getAllCategories();
    categories = result.categories;
  });
</script>

<div class="flex h-full w-full flex-col">
  <div
    class="flex h-16 w-full flex-row items-center justify-start border-b border-light-grey px-6"
  >
    <h1 class="mt-1 text-xl font-bold text-primary">
      {$t('dashboard.experiences.title')}
    </h1>
  </div>
  <div class="flex h-16 w-full flex-row items-center justify-start gap-4 px-6">
    <div class="flex flex-row items-center">
      <label
        for="language"
        class="text-md me-3 whitespace-nowrap font-medium text-dark-grey"
        >{$t('dashboard.experiences.language-label')}</label
      >
      <select
        name="language"
        class="h-8 w-full pl-2 pr-8 text-sm capitalize text-gray-700"
        data-cy="experience-language-selector"
        on:change={(e) => handleLanguageChange(e.currentTarget.value)}
      >
        {#each Object.values(ExperienceLanguages) as language}
          <option value={language} selected={language === selectedLanguage}
            >{language === 'English' ? 'English' : 'Español'}</option
          >
        {/each}
      </select>
    </div>
    <div class="flex flex-row items-center justify-center">
      <label
        for="category-filter"
        class="text-md me-3 whitespace-nowrap font-medium text-dark-grey"
        >{$t('dashboard.experiences.category')}</label
      >
      <select
        name="category-filter"
        class="h-8 w-full pl-2 pr-8 text-sm capitalize text-gray-700"
        data-cy="category-filter"
        on:change={(e) => handleCategoryChange(+e.currentTarget.value)}
      >
        <option value={0}>{$locale === 'en' ? 'All' : 'Todas'}</option>
        {#each categories as category}
          <option value={category.id}
            >{$locale === 'en'
              ? category.englishName
              : category.spanishName}</option
          >
        {/each}
      </select>
    </div>
  </div>
  <div class="flex w-full flex-col gap-2 p-6">
    {#await experiencesPromise}
      <div class="flex h-80 w-full grow items-center justify-center">
        <Loading loadingSize="h-32 w-32" />
      </div>
    {:then { experiences, count }}
      {#if count === 0}
        <span class="text-dark-grey"
          >{$t('dashboard.experiences.no-experiences')}</span
        >
      {:else}
        <div class="flex w-full flex-wrap gap-4">
          {#each experiences as experience}
            <ExperienceCard
              blockUser={checkIfAllowed(experience)}
              experience={experience}
              startSession={() => handleExperienceDetails(experience)}
            />
          {/each}
        </div>
        <div class="p-4">
          <Paginator
            total={count}
            query={query}
            onChange={(newQuery) => {
              query = newQuery;
            }}
          />
        </div>
      {/if}
    {:catch error}
      <p class="text-center" data-cy="error-message">
        {$t('dashboard.experiences.fetch-error', { error: error.message })}
      </p>
    {/await}
  </div>
</div>
