<script lang="ts">
  import { navigate } from 'svelte-navigator';
  import ChevronIcon from '@/assets/svg/ChevronIcon.svelte';
  import LockIcon from '@/assets/svg/LockIcon.svelte';
  import StarIcon from '@/assets/svg/StarIcon.svelte';
  import { planNames, type IExperience } from '@/interface/IExperience';
  import { ToastNotification } from '@/lib/services/toast';
  import { t } from '@/locales/i18n';

  import Button from '../button/Button.svelte';
  import UserIcon from '../icons/UserIcon.svelte';

  export let experience: IExperience;
  export let isLandingPageCard = false;
  export let blockUser = false;
  export let startSession: ((experience: IExperience) => void) | undefined =
    undefined;

  const handleBlockUser = () => {
    ToastNotification.error(
      `You must have a ${
        planNames[experience.allowInPlan]
      } plan to use this experience`,
    );
  };

  $: rating = (experience.totalRatings / experience.totalRaters).toFixed(1);
</script>

<div
  class="{isLandingPageCard
    ? 'w-fit min-w-[26rem] sm:max-w-[16rem] md:min-w-[24rem] md:max-w-fit lg:min-w-[20rem] lg:max-w-[32rem] xl:min-w-[22rem] min-[1650px]:w-[30rem]'
    : 'w-96'} flex flex-col justify-between overflow-hidden rounded-lg border border-light-grey bg-white"
>
  <div>
    <img
      class="h-72 w-full object-cover object-center"
      src={experience.imageUrl}
      alt={experience.name}
    />
    <div class="flex w-full flex-col gap-3 p-4">
      <div class="flex flex-row items-center justify-between">
        {#if !isLandingPageCard}
          <div class="flex flex-row items-center justify-center gap-2">
            <UserIcon
              style="bg-primary w-6 h-6 p-1 relative bottom-[.04rem] rounded-full bg-primary fill-white"
            /><span class="font-semibold text-primary">
              {experience.minParticipants}
              {experience.maxParticipants
                ? `- ${experience.maxParticipants}`
                : ''}</span
            >
          </div>
        {/if}
        <div class="flex flex-row items-center justify-center gap-2">
          <span class="py-1 text-grey"
            >{experience.totalRaters === 0 ? '0' : rating}</span
          >
          <StarIcon style="mb-[0.25rem] fill-grey" />
        </div>
      </div>
      <div>
        <h4 class="font-bogart text-lg font-extrabold text-primary">
          {experience.name}
        </h4>
        <p class="my-2 text-grey">{experience.description}</p>
      </div>
    </div>
  </div>
  <div class="flex flex-row items-center justify-between p-4">
    {#if isLandingPageCard}
      <Button
        style="outline-reverse"
        testId={`landing-page-play-experience-button-${experience.id}`}
        on:click={() => navigate('/login')}
      >
        <span>{$t('experience.play-button')}</span>
        <ChevronIcon
          style="w-[0.4rem] mx-1 fill-white group-hover:fill-gray-500 transition ease-in duration-100"
        />
      </Button>
    {:else}
      <Button
        testId={`play-experience-button-${experience.id}`}
        disabled={!startSession}
        style="outline"
        on:click={() =>
          blockUser ? handleBlockUser() : startSession?.(experience)}
      >
        <span>{$t('experience.play-button')}</span>
        {#if blockUser}
          <LockIcon
            buttonLockIconTextColor="fill-grey group-hover:fill-white transition ease-in duration-100"
          />
        {:else}
          <ChevronIcon style="w-[0.4rem] mx-1" />
        {/if}
      </Button>
      <Button
        disabled={blockUser}
        testId={`view-experience-board-button-${experience.id}`}
        style="outline"
        on:click={() =>
          !blockUser && window.open(experience.templateUrl, '_blank')}
      >
        <span>{$t('experience.view-board-button')}</span>
      </Button>
    {/if}
  </div>
</div>
