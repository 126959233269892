<script lang="ts">
  import { onMount } from 'svelte';
  import { navigate, Route, Router } from 'svelte-navigator';
  import { UserRole } from '@/interface/IUser';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  import BillingSettings from './billing/BillingSettings.svelte';
  import GeneralSettings from './general/GeneralSettings.svelte';
  import OrganizationSettings from './organization/OrganizationSettings.svelte';
  import ProfileSettings from './profile/ProfileSettings.svelte';

  type SettingsTab = {
    name: string;
    label: string;
    authority: UserRole[];
    routePath: string;
  };

  let tabs: SettingsTab[] = [
    {
      name: 'general',
      label: $t('dashboard.settings.tabs.general'),
      authority: [
        UserRole.ADMIN,
        UserRole.SUPER_ADMIN,
        UserRole.HOST,
        UserRole.USER,
      ],
      routePath: '/general',
    },
    {
      name: 'billing',
      label: $t('dashboard.settings.tabs.billing'),
      authority: [UserRole.ADMIN],
      routePath: '/billing',
    },
    {
      name: 'organization',
      label: $t('dashboard.settings.tabs.organization'),
      authority: [UserRole.ADMIN],
      routePath: '/organization',
    },
    {
      name: 'profile',
      label: $t('dashboard.settings.tabs.profile'),
      authority: [
        UserRole.ADMIN,
        UserRole.SUPER_ADMIN,
        UserRole.HOST,
        UserRole.USER,
      ],
      routePath: '/profile',
    },
  ];

  let activeTab = 'general';

  function switchTab(tab: string) {
    activeTab = tab;
    navigate(`/dashboard/settings/${tab}`);
  }

  onMount(() => {
    switchTab(activeTab);
  });
</script>

<Router basepath="/dashboard/settings/*">
  <Route path="/*">
    <div
      class="flex h-16 w-full flex-row items-center justify-start border-b border-light-grey px-6"
    >
      <h1 class="mt-1 text-xl font-bold text-primary">
        {$t('dashboard.settings.title')}
      </h1>
    </div>

    <div class="my-2 ms-2 flex flex-wrap items-center gap-2">
      {#each tabs as tab}
        {#if $userStore && tab.authority.includes($userStore.role)}
          <button
            class={`rounded-t-lg px-4 py-2 text-gray-600 hover:bg-primary hover:text-white focus:bg-primary focus:opacity-90 focus:outline-none ${
              activeTab === tab.name ? 'bg-primary text-white opacity-90' : ''
            }`}
            on:click={() => switchTab(tab.name)}
            data-cy={`settings-tab-${tab.name}`}
          >
            {tab.label}
          </button>
        {/if}
      {/each}
    </div>

    <div class="border-t border-gray-200">
      {#if activeTab === 'general'}
        <Route path="/general">
          <GeneralSettings />
        </Route>
      {:else if activeTab === 'billing'}
        <Route path="/billing">
          <BillingSettings />
        </Route>
      {:else if activeTab === 'organization'}
        <Route path="/organization">
          <OrganizationSettings />
        </Route>
      {:else if activeTab === 'profile'}
        <Route path="/profile">
          <ProfileSettings />
        </Route>
      {/if}
    </div>
  </Route>
</Router>
