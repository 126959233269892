<script lang="ts">
  import { navigate } from 'svelte-navigator';
  import BackArrow from '@/assets/svg/BackArrow.svelte';
  import { ExperienceLanguages } from '@/interface/IExperience';
  import MultiStepFormHeader from '@/lib/components/multi-step/MultiStepFormHeader.svelte';
  import type {
    SignupFormStepType,
    StepsState,
    TrialSessionFormStepType,
  } from '@/lib/components/signup-form/@types/SubmissionFormTypes';
  import SignupForm from '@/lib/components/signup-form/signup-form-step/SignupForm.svelte';
  import SignupInfo from '@/lib/components/signup-form/signup-info-step/SignupInfo.svelte';
  import TrialSessionForm from '@/lib/components/signup-form/trial-session-form-step/TrialSessionForm.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import { sendSubmission } from '@/lib/services/together/onboarding.api';
  import { locale, t } from '@/locales/i18n';
  import { submissionFormStore } from '@/store/submissionFormStore';

  enum StepsIndex {
    SIGNUP_STEP = 0,
    TRIAL_SESSION_STEP = 1,
    INFO_STEP = 2,
  }

  let multiStepForm: MultiStepFormHeader;
  let currentStep = 0;
  let stepsTitle = ['Sign Up', 'Trial Session', 'Info'] as const;
  let currentStepTitle: (typeof stepsTitle)[number] = stepsTitle[0];

  let stepsState: StepsState = [
    {
      name: $submissionFormStore?.name || '',
      lastName: $submissionFormStore?.lastName || '',
      email: $submissionFormStore?.email || '',
      orgName: $submissionFormStore?.orgName || '',
      acceptTerms: $submissionFormStore?.acceptTerms || false,
    },
    {
      applyTrial: $submissionFormStore?.applyTrial || false,
      trialSessionScheduleDate:
        $submissionFormStore?.trialSessionScheduleDate || '',
      trialSessionParticipantsMails:
        $submissionFormStore?.trialSessionParticipantsMails || '',
      language:
        $locale === 'en'
          ? ExperienceLanguages.english
          : ExperienceLanguages.spanish,
    },
  ];

  async function onSubmit(
    values: SignupFormStepType | TrialSessionFormStepType,
  ) {
    stepsState[currentStep] = values;
    stepsState = stepsState;
    $submissionFormStore = {
      ...$submissionFormStore,
      ...stepsState[currentStep],
    };

    if (currentStep === StepsIndex.TRIAL_SESSION_STEP) {
      try {
        await sendSubmission({
          orgName: $submissionFormStore?.orgName,
          name: $submissionFormStore?.name,
          lastName: $submissionFormStore?.lastName,
          email: $submissionFormStore?.email,
          applyTrial: $submissionFormStore?.applyTrial,
          trialSessionParticipantsMails:
            $submissionFormStore?.trialSessionParticipantsMails,
          trialSessionScheduledDate:
            $submissionFormStore?.trialSessionScheduleDate
              ? new Date(
                  $submissionFormStore?.trialSessionScheduleDate,
                ).toLocaleDateString()
              : undefined,
          language: $submissionFormStore?.language,
        });

        ToastNotification.success($t('signup.form-success'));
      } catch (error: any) {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage) {
          ToastNotification.error(errorMessage);
        } else {
          ToastNotification.error($t('signup.form-error'));
        }
      }
    }
    currentStep += 1;
    multiStepForm.nextStep();
  }

  function onBack(values: SignupFormStepType | TrialSessionFormStepType) {
    if (currentStep === StepsIndex.SIGNUP_STEP) return;
    stepsState[currentStep] = values;
    stepsState = stepsState;
    $submissionFormStore = {
      ...$submissionFormStore,
      ...stepsState[currentStep],
    };

    currentStep -= 1;
    multiStepForm.prevStep();
  }

  $: $submissionFormStore,
    submissionFormStore.set({
      ...$submissionFormStore,
      ...stepsState[currentStep],
    });
</script>

<div class="z-10 flex h-[45rem] w-full flex-col items-center justify-center">
  <div class="mb-8 flex items-center justify-start gap-12 md:gap-24">
    <button
      class="z-20 -ml-32 text-gray-500 transition duration-300 hover:text-gray-700 md:-ml-60"
      on:click={() => navigate('/', { replace: true })}
    >
      <BackArrow />
    </button>
    <MultiStepFormHeader
      styles="w-[12rem] z-20 mb-1"
      bind:this={multiStepForm}
      bind:currentStep={currentStepTitle}
      steps={stepsTitle}
    />
  </div>
  <h2
    class="mb-8 text-center text-5xl font-bold text-primary"
    data-cy="submission-form-title"
  >
    {@html $t('signup.form-title')}
  </h2>
  <div>
    {#if currentStep === StepsIndex.SIGNUP_STEP}
      <SignupForm
        onSubmit={onSubmit}
        onBack={onBack}
        initialValues={stepsState[StepsIndex.SIGNUP_STEP]}
      />
    {:else if currentStep === StepsIndex.TRIAL_SESSION_STEP}
      <TrialSessionForm
        onSubmit={onSubmit}
        onBack={onBack}
        initialValues={stepsState[StepsIndex.TRIAL_SESSION_STEP]}
      />
    {:else if currentStep === StepsIndex.INFO_STEP}
      <SignupInfo />
    {/if}
  </div>
</div>
