<script lang="ts">
  import { validator } from '@felte/validator-yup';
  import { createForm } from 'felte';
  import { onMount } from 'svelte';
  import * as yup from 'yup';
  import AvatarPlaceholder from '@/assets/img/avatar-placeholder.png';
  import Input from '@/lib/components/input/Input.svelte';
  import Page from '@/lib/components/page/Page.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import { uploadImage } from '@/lib/services/together/together.api';
  import { updateUserProfile } from '@/lib/services/together/user.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  let imageFile: File | null = null;
  let imageError: string | null = null;

  const schema = yup.object({
    name: yup.string().max(20, $t('dashboard.settings.profile.form.name-max')),
    lastName: yup
      .string()
      .max(20, $t('dashboard.settings.profile.form.last-name-max')),
    avatarUrl: yup.mixed(),
  });

  const { form, errors, isValid, isSubmitting } = createForm<
    yup.InferType<typeof schema>
  >({
    initialValues: {
      name: $userStore?.name || '',
      lastName: $userStore?.lastName || '',
      avatarUrl: $userStore?.avatarUrl || '',
    },
    extend: [validator({ schema })],
    onSubmit: async (values) => {
      try {
        let imageUrl: string | null = null;
        if (imageFile && values.avatarUrl === '') {
          imageUrl = await uploadImage(imageFile);
        }

        await updateUserProfile({
          name: values.name,
          lastName: values.lastName,
          avatarUrl: imageUrl || values.avatarUrl,
        });

        ToastNotification.success(
          $t('dashboard.settings.profile.form.submit-success'),
          {
            duration: 2000,
            onpop: () => {
              window.location.reload();
            },
          },
        );
      } catch (error: any) {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage) {
          ToastNotification.error(errorMessage);
        } else {
          ToastNotification.error(
            $t('dashboard.settings.profile.form.submit-error'),
          );
        }
      }
    },
  });

  let imgPreview = AvatarPlaceholder;
  const handlePreview = async (e: Event) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (file) {
      imgPreview = URL.createObjectURL(file);
      imageFile = file;
    }
  };

  const handleRemoveImage = () => {
    imgPreview = AvatarPlaceholder;
    imageFile = null;
    imageError = null;
  };

  onMount(() => {
    const fileInput = document.getElementById('avatarFileInput');

    fileInput?.addEventListener('change', (e) => {
      imageError = null;
      const file = (e.target as HTMLInputElement)?.files?.[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const imageData = event?.target?.result;
        const image = new Image();

        image.onload = () => {
          const width = image.width;
          const height = image.height;
          const fileSize = file?.size;

          const maxResolution = 1024;
          const maxAspectRatio = 1;
          const maxSize = 2 * 1024 * 1024;
          const aspectRatio = width / height;

          if (width > maxResolution || height > maxResolution) {
            imageError = $t(
              'dashboard.settings.profile.form.avatar-max-resolution',
            );
          }

          if (
            aspectRatio > maxAspectRatio ||
            aspectRatio < 1 / maxAspectRatio
          ) {
            imageError = $t(
              'dashboard.settings.profile.form.avatar-aspect-ratio',
            );
          }

          if (fileSize && fileSize > maxSize) {
            imageError = $t('dashboard.settings.profile.form.avatar-max-size');
          }
        };
        if (typeof imageData === 'string') {
          image.src = imageData;
        }
      };
      if (file instanceof Blob) {
        reader.readAsDataURL(file);
      }
    });
  });
</script>

<Page>
  <div
    class="h-max-fit flex w-full flex-col gap-4 rounded-none border-x border-t border-light-grey bg-white p-8"
  >
    <h1 class="mt-1 text-3xl font-bold">
      {$t('dashboard.settings.profile.title')}
    </h1>
    <p>{$t('dashboard.settings.profile.description')}</p>
    <form use:form class="mt-4 flex flex-col justify-center">
      <div class="flex w-1/2 flex-row items-center justify-center gap-12">
        <div class="flex w-full flex-col">
          <div class="mb-4">
            <label
              for="name"
              class="text-md ms-1 block font-medium text-gray-700"
              >{$t('dashboard.settings.profile.form.name-label')}</label
            >
            <Input
              type="text"
              placeholder={$t(
                'dashboard.settings.profile.form-name-placeholder',
              )}
              name="name"
              styleClass="mt-1"
              testId="profile-name-input"
            />
            {#if $errors.name}
              <p class="mt-2 text-sm text-red-600">{$errors.name}</p>
            {/if}
          </div>
          <div class="mb-4">
            <label
              for="lastName"
              class="text-md ms-1 block font-medium text-gray-700"
              >{$t('dashboard.settings.profile.form.last-name-label')}</label
            >
            <Input
              type="text"
              placeholder={$t(
                'dashboard.settings.profile.form.last-name-placeholder',
              )}
              name="lastName"
              styleClass="mt-1"
              testId="profile-last-name-input"
            />
            {#if $errors.lastName}
              <p class="mt-2 text-sm text-red-600">{$errors.lastName}</p>
            {/if}
          </div>
        </div>
        <div class="flex h-fit w-full flex-col items-center justify-center">
          {#if $userStore?.avatarUrl || imgPreview}
            <div class="flex items-center">
              <img
                src={$userStore?.avatarUrl || imgPreview}
                alt="avatar thumbnail"
                class="h-20 w-24 object-cover"
              />
              {#if imgPreview !== AvatarPlaceholder && imgPreview}
                <button
                  type="button"
                  class="ml-2 rounded bg-red-500 px-2 py-1 font-bold text-white hover:bg-red-700"
                  on:click={handleRemoveImage}
                >
                  X
                </button>
              {/if}
            </div>
            {#if imageError}
              <p class="mt-2 text-sm text-red-600">{imageError}</p>
            {/if}
          {/if}
          <div class="flex h-full w-full flex-col gap-2">
            <label
              for="avatarFileInput"
              class="w-full text-sm font-medium text-gray-900"
              >{$t('dashboard.settings.profile.form.avatar-label')}</label
            >
            <Input
              name="avatarFileInput"
              styleClass="w-full"
              type="image"
              on:change={handlePreview}
              testId="profile-avatar-input"
            />
          </div>
        </div>
      </div>
      <div
        class="fixed bottom-0 left-0 right-0 z-10 border-t-2 bg-gray-100 p-4"
      >
        <div class="flex w-full flex-row items-center justify-end gap-3">
          <button
            type="submit"
            disabled={$isSubmitting || imageError !== null || !$isValid}
            class="hover:bg-primary-focus rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 {$isSubmitting ||
            imageError !== null ||
            !$isValid
              ? 'cursor-not-allowed bg-primary/50'
              : ''}"
            data-cy="profile-settings-submit-btn"
          >
            {$t('dashboard.settings.profile.form-submit-btn')}
          </button>
        </div>
      </div>
    </form>
  </div>
</Page>
