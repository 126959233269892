<script lang="ts">
  import { validator } from '@felte/validator-yup';
  import { createForm } from 'felte';
  import * as yup from 'yup';
  import Button from '@/lib/components/button/Button.svelte';
  import Input from '@/lib/components/input/Input.svelte';
  import type { TrialSessionFormStepType } from '@/lib/components/signup-form/@types/SubmissionFormTypes';
  import { trialSessionFormSchema } from '@/lib/components/signup-form/schema/submission-form.schema';
  import { t } from '@/locales/i18n';

  export let initialValues: TrialSessionFormStepType;
  export let onSubmit: (values: any) => void;
  export let onBack: (values: any) => void;

  let applyTrial = initialValues.applyTrial || false;

  const { form, errors, data, isSubmitting, isValid } = createForm<
    yup.InferType<typeof trialSessionFormSchema>
  >({
    initialValues: {
      applyTrial: initialValues.applyTrial || false,
      trialSessionScheduleDate: initialValues.trialSessionScheduleDate || '',
      trialSessionParticipantsMails:
        initialValues.trialSessionParticipantsMails,
    },
    extend: [validator({ schema: trialSessionFormSchema })],
    onSubmit: onSubmit,
  });
</script>

<p
  class="mb-4 text-center text-xl text-dark-grey"
  data-cy="submission-form-subtitle"
>
  {@html $t('signup.form-subtitle')}
</p>
<form use:form class="flex w-full flex-col gap-4" data-cy="trial-session-form">
  <Input
    bind:checked={initialValues.applyTrial}
    type="checkbox"
    name="applyTrial"
    testId="trial-session-form-apply-trial"
    on:change={() => (applyTrial = !applyTrial)}
  >
    <span>{$t('signup.form-trial-session-apply-trial-label')}</span>
  </Input>
  {#if applyTrial}
    <div class="flex flex-col gap-2">
      <p class="text-xs text-grey" data-cy="trial-session-form-instructions">
        {$t('signup.form-trial-session-trial-instructions')}
      </p>

      <Input
        bind:value={initialValues.trialSessionScheduleDate}
        type="date"
        placeholder={$t(
          'signup.form-trial-session-schedule-date-input-placeholder',
        )}
        name="trialSessionScheduleDate"
        error={$errors['trialSessionScheduleDate']
          ? $errors['trialSessionScheduleDate']?.[0]
          : ''}
        testId="trial-session-form-schedule-date"
      />
      <Input
        bind:value={initialValues.trialSessionParticipantsMails}
        type="text"
        placeholder={$t(
          'signup.form-trial-session-participants-mails-input-placeholder',
        )}
        name="trialSessionParticipantsMails"
        error={$errors['trialSessionParticipantsMails']
          ? $errors['trialSessionParticipantsMails']?.[0]
          : ''}
        testId="trial-session-form-participants-mails"
      />
    </div>
  {/if}
  <div class="mt-4 flex items-center justify-center gap-4">
    <Button testId="trial-session-form-back-btn" on:click={() => onBack($data)}>
      {$t('signup.button-back')}
    </Button>
    <Button
      submit={true}
      disabled={$isSubmitting || !$isValid}
      testId="trial-session-form-submit-btn"
      >{$t('signup.button-submit')}</Button
    >
  </div>
</form>
